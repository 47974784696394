<template>
  <div class="wrap">
    <head-nav></head-nav>
    <div class="con-wrap">
      <side-bar></side-bar>
      <div class="pages-con-wrap">
        <b-container fluid="100%">
          <div class="page-label">个人资料</div>
          <b-container>
            <div class="user-avatar-wrap">
              <div class="user-avatar" @click="openChangeAvatar">
                <img :src="avatar" alt="">
                <!-- <el-upload 
                  ref="field118" 
                  :limit="1" 
                  :action="upload.url" 
                  accept=".png,.jpg,.gif" 
                  list-type="picture-card"
                  :on-success="handleUploadSuccess" 
                  :before-upload="handleBeforeUpload" 
                  :on-error="handleUploadError"
                  :file-list="imgList" 
                  :headers="upload.headers"
                  :data='upload.data'
                  name="file" 
                  :show-file-list="false"
                  style="display: inline-block; vertical-align: top">
                  <el-image v-if="!logoUrl" :src="logoUrl">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-plus" />
                    </div>
                  </el-image>
                  <div v-else>
                    <el-image :src="logoUrl" :style="`width:146px;height:146px;`" fit="fill" />
                    <div class="mask addmask">
                      <div class="actions">
                        <span title="预览" @click.stop="upload.dialogVisibleTwo = true">
                          <i class="el-icon-zoom-in" />
                        </span>
                        <span title="移除" @click.stop="removeImage">
                          <i class="el-icon-delete" />
                        </span>
                      </div>
                    </div>
                  </div>
                </el-upload> -->
                <!-- 预览本地图片 -->
                <!-- <el-dialog :visible.sync="upload.dialogVisibleTwo" title="预览" width="800" append-to-body>
                  <img :src="logoUrl" style="display: block; max-width: 100%; margin: 0 auto;">
                </el-dialog> -->
              </div>
              <div class="user-avatar-tip">一个好的头像可以让更多人关注你，用于网站内展示（支持JPG、PNG，大小不超过1M）</div>
            </div>
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
              <b-row>
                <b-col sm="12" md="12" lg="12" xl="12">
                  <el-form-item label="用户ID：">
                    <div class="user-id">13751081328</div>
                  </el-form-item>
                </b-col>
                <b-col sm="12" md="12" lg="6" xl="6">
                  <el-form-item label="昵称：" prop="nickName">
                    <el-input v-model="form.nickName" placeholder="许淇栋（形与品设计）"></el-input>
                  </el-form-item>
                </b-col>
                <b-col sm="12" md="12" lg="6" xl="6">
                  <el-form-item label="真实姓名：" prop="name">
                    <el-input v-model="form.name" placeholder="请输入真实姓名"></el-input>
                  </el-form-item>
                </b-col>
                <b-col sm="12" md="12" lg="6" xl="6">
                  <el-form-item label="QQ/微信号：">
                    <el-input v-model="form.QQOrWx" placeholder="请输入联系QQ/微信号"></el-input>
                  </el-form-item>
                </b-col>
                <b-col sm="12" md="12" lg="6" xl="6">
                  <el-form-item label="公司名称：" prop="corporateName">
                    <el-input v-model="form.corporateName" placeholder="请输入公司名称"></el-input>
                  </el-form-item>
                </b-col>
                <b-col sm="12" md="12" lg="12" xl="12">
                  <el-form-item label="性别：">
                    <el-radio-group v-model="form.radio">
                      <el-radio :label="1">男</el-radio>
                      <el-radio :label="2">女</el-radio>
                      <el-radio :label="3">保密</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </b-col>
                <b-col sm="12" md="12" lg="7" xl="7">
                  <el-form-item label="城市：">
                    <li-area-select v-model="defultAddress"></li-area-select>
                  </el-form-item>
                </b-col>
                <b-col sm="12" md="12" lg="7" xl="7">
                  <el-form-item label="个性签名：">
                    <el-input v-model="form.signature" placeholder="请输入个性签名"></el-input>
                  </el-form-item>
                </b-col>
                <b-col sm="12" md="12" lg="12" xl="12" class="btn-wrap">
                  <div class="submit-btn">保存修改</div>
                </b-col>
              </b-row>
            </el-form>
          </b-container>
          <!-- <div class="supplement-info">
            <h2>资料补充<i></i></h2>
            <p>亲爱的胜捷美家用户， 您好！ 希望您花费1分钟左右的时间来填写完善该资料， 完善提交即可获得<span>200e币</span>！ （ 注：提交后不能修改 ）</p>
          </div>
          <b-container>
            <div class="supplement-info-wrap">
              <el-form :model="form" label-width="120px">
                <el-form-item label="您的年龄？">
                  <el-radio-group v-model="form.ageRadio">
                    <el-radio :label="1">19岁以下</el-radio>
                    <el-radio :label="2">20-29岁</el-radio>
                    <el-radio :label="3">30-39岁</el-radio>
                    <el-radio :label="4">40-49岁</el-radio>
                    <el-radio :label="5">50岁以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="您的学历？">
                  <el-radio-group v-model="form.education">
                    <el-radio :label="1">高中 /中专/技校</el-radio>
                    <el-radio :label="2">大学专科</el-radio>
                    <el-radio :label="3">大学本科及以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="您的职业？">
                  <el-radio-group v-model="form.occupation">
                    <el-radio :label="1">室内设计师</el-radio>
                    <el-radio :label="2">软装设计师</el-radio>
                    <el-radio :label="3">设计工作室/公司</el-radio>
                    <el-radio :label="4">装饰装修公司</el-radio>
                    <el-radio :label="5">建筑工程公司</el-radio>
                    <el-radio :label="6">家居门店</el-radio>
                    <el-radio :label="7">家居饰品工厂</el-radio>
                    <el-radio :label="8">家居导购/销售</el-radio>
                    <el-radio :label="9">材料供应商/经销商（家具/灯具/软配/橱卫/建材等）</el-radio>
                    <el-radio :label="10">学生</el-radio>
                    <el-radio :label="11">其他（地产商等）</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="您的工作年限？">
                  <el-radio-group v-model="form.workingYears">
                    <el-radio :label="1">在校</el-radio>
                    <el-radio :label="2">一年以下</el-radio>
                    <el-radio :label="3">1-3年</el-radio>
                    <el-radio :label="4">4-6年</el-radio>
                    <el-radio :label="5">7-9年</el-radio>
                    <el-radio :label="6">10年以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div class="submit-btn">保存修改</div>
              </el-form>
            </div>
          </b-container> -->
        </b-container>
      </div>
    </div>
    <footer-nav></footer-nav>
    <!-- :http-request="uploadAvatar" :on-change="changeAvatar" -->
    <el-dialog title="更换头像" :visible.sync="openDialog" width="30%" center>
      <el-upload 
        class="avatar-uploader" 
        ref="upload"
        :limit="1"
        :action="upload.url"
        :show-file-list="false"
        accept=".png,.jpg" 
        :auto-upload="false"
        :on-change="changeAvatar"
        :on-success="avatarUploadSuccess"
        name="file" >
        <img v-if="upload.imageUrl" :src="upload.imageUrl" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openDialog = false">取 消</el-button>
        <el-button type="primary" @click="uploadSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import headNav from "@/components/Navbar";
  import sideBar from "@/components/userCenterSidebar";
  import liAreaSelect from "@/components/liAreaSelect";
  import footerNav from "@/components/Footer";
  import Url from "@/api/config";
  export default {
    components: {
      headNav,
      sideBar,
      liAreaSelect,
      footerNav
    },
    data() {
      return {
        form: {
          nickName: "",
          name: "",
          QQOrWx: "",
          corporateName: "",
          radio: 3,
          signature: "",
          ageRadio: 2,
          education: 2,
          occupation: "",
          workingYears: ""
        },
        rules: {

        },
        //默认值设置，可为空
        defultAddress: {
          sheng: '',
          shi: '',
          qu: '',
        },
        token: null,
        avatar: "",
        openDialog: false,
        upload: {
          dialogVisibleTwo: false,
          // 是否禁用上传
          isUploading: false,
          // 设置上传的请求头部
          headers: {
            method: "post",
            // Authorization: "Bearer " + localStorage.getItem("token")
          },
          imageUrl: "",
          // 上传的地址
          url: '',
          data: {
            token: ''
          }
        },
        imgList: [],
        logoUrl: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/3f7eb5b6-b193-4821-969f-d930d55ce260.png"
      }
    },
    created() {
      this.token = this.upload.data.token = localStorage.getItem("token");
      this.avatar = localStorage.getItem("userAvatar") ? localStorage.getItem("userAvatar") : "./icon/user-default-avatar.jpg";
      this.upload.url = Url + "/ossupload/uploadImg.do";
    },
    methods: {
      // 打开头像上传窗口
      openChangeAvatar() {
        this.openDialog = !this.openDialog;
      },
      uploadSubmit(){
        console.log(this.$refs.upload)
        this.$refs.upload.submit();
      },
      changeAvatar(file){
        console.log(file)
        const isJPGOrPNG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
        const isLt1M = file.size / 1024 / 1024 < 1;
        
        if (!isJPGOrPNG) {
          return this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
        }
        if (!isLt1M) {
          return this.$message.error('上传头像图片大小不能超过 1MB!');
        }
        this.upload.imageUrl = URL.createObjectURL(file.raw);
      },
      avatarUploadSuccess(res, file){
        console.log(res, file)
        const avatarUrl = res.data;
        this.$api.changeAvatar({
          url: avatarUrl,
          token: this.token
        }).then(res => {
          console.log(res)
          if(res.status == 100){
            this.openChangeAvatar();
            localStorage.setItem("userAvatar", avatarUrl);
            this.$message({
              message: res.msg,
              type: 'success'
            });
            setTimeout(()=>{
              this.$router.go(0);
            },800)
          }
        }).catch(err => console.error(err))
      },
      uploadAvatar(params){
        console.log(params)
      }
      
      
      
      // 图片上传==================获取到coverId,拿到图片路径回显
      // handleUploadSuccess(res) {
      //   this.$emit('input', res.url)
      //   this.loading.close()
      //   this.formData.coverId = res.fileId
      //   this.logoUrl = process.env.VUE_APP_BASE_API + res.url
      //   this.$api.changeAvatar({
      //     url: res.url,
      //     token: this.token
      //   }).then(res => {
      //     console.log(res)
      //     if(res.status == 100){
      //       this.$message({
      //         message: res.msg,
      //         type: 'success'
      //       });
      //     }
      //   }).catch(err => console.error(err))
        
      // },
      // handleBeforeUpload(file) {
      //   console.log(file)
      //   const isJPGOrPNG = file.type === 'image/jpeg' || file.type === 'image/png';
      //   const isLt1M = file.size / 1024 / 1024 < 1;
        
      //   if (!isJPGOrPNG) {
      //     this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
      //   }
      //   if (!isLt1M) {
      //     this.$message.error('上传头像图片大小不能超过 1MB!');
      //   }
        
      //   this.$confirm('您确定要修改当前头像吗？', '温馨提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     // this.$message({
      //     //   type: 'success',
      //     //   message: '删除成功!'
      //     // });
      //   }).catch(() => {
      //     this.$message({
      //       type: 'info',
      //       message: '已取消'
      //     });   
      //     return 
      //   });

      //   this.loading = this.$loading({
      //     lock: true,
      //     text: '上传中',
      //     background: 'rgba(0, 0, 0, 0.7)',
      //   })
      //   return isJPGOrPNG && isLt1M;
      // },
      // handleUploadError() {
      //   this.$message({
      //     type: 'error',
      //     message: '上传失败',
      //   })
      //   this.loading.close()
      // },
      // // 图片清空=================注意
      // removeImage() {
      //   this.logoUrl = ''
      //   this.imgList = []
      //   this.formData.coverId = ""
      //   //   this.$refs.field118.clearFiles()
      // },
      
      
    }

  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/userInfo/userInfo";
</style>
